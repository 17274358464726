/* eslint-disable prettier/prettier */
import { FixedNumber } from "@ethersproject/bignumber";
import { walletStore } from "@/stores/wallet-store";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { blockchainHandler } from "@/blockchain";
import moment from "moment";
import Web3 from "web3";

//TODO: Change to mainnet
const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!;

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on("receipt", (result) => resolve(result))
      .on("error", (error) => reject(error));
  });
}

export class EggHatchHandler {
  LPTokenContract: any;
  lockedAmount?: FixedNumber;

  lotteryContract?: any;
  usedTicket?: FixedNumber;
  summonedIds?: Array<string>;
  isRares?: Array<boolean>;

  constructor() {
    this.lotteryContract = new web3.eth.Contract(
      require("./lottery2.abi.json"),
      process.env.VUE_APP_LOTTERY2_ADDRESS
    );
  }

  async load() {
    loadingController.increaseRequest();
    try {
      this.LPTokenContract = new web3.eth.Contract(
        require("./erc20.abi.json"),
        process.env.VUE_APP_TOKEN_ADDRESS
      );
    } catch (error) {
      console.error(error);
    } finally {
      loadingController.decreaseRequest();
    }
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.LPTokenContract = new web3.eth.Contract(
        require("./erc20.abi.json"),
        process.env.VUE_APP_TOKEN_ADDRESS
      );
      this.lotteryContract = new web3.eth.Contract(
        require("./lottery2.abi.json"),
        process.env.VUE_APP_LOTTERY2_ADDRESS
      );
    }
  }

  async getStandardTicketNo() {
    const standardTicketNo = await this.lotteryContract.methods
      .normalTickets(walletStore.account)
      .call();
    return FixedNumber.from(standardTicketNo);
  }

  async getPremiumTicketNo() {
    const premiumTicketNo = await this.lotteryContract.methods
      .premiumTickets(walletStore.account)
      .call();
    return FixedNumber.from(premiumTicketNo);
  }

  async premiumSpin(account) {
    const f = this.lotteryContract.methods.sprin(1);
    const res = await sendRequest(f, account);
    const summonedEvent = (res as any).events["Summoned"];
    this.summonedIds = summonedEvent.returnValues.summonedIds as Array<string>;
    this.isRares = summonedEvent.returnValues.isRares as Array<boolean>;
  }

  async standardSpin(account) {
    const f = this.lotteryContract.methods.sprin(0);
    const res = await sendRequest(f, account);
    const summonedEvent = (res as any).events["Summoned"];
    this.summonedIds = summonedEvent.returnValues.summonedIds as Array<string>;
    this.isRares = summonedEvent.returnValues.isRares as Array<boolean>;
  }

  async approvedLottery(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_LOTTERY2_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approveLottery(account) {
    try {
      const f = this.LPTokenContract.methods.approve(
        process.env.VUE_APP_LOTTERY2_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.error("error", error);
    }
  }

  async getUserLPBalance(account) {
    const allowance = await this.LPTokenContract.methods
      .balanceOf(account)
      .call();
    return FixedNumber.from(`${web3.utils.fromWei(allowance)}`);
  }
}
