
























































































import { Observer } from "mobx-vue";
import { Component, Vue, Inject, Ref } from "vue-property-decorator";
import { EggHatchViewModel } from "../viewmodels/egg-hatch-viewmodel";

@Observer
@Component({
  components: {},
})
export default class SummonDialog extends Vue {
  @Inject() vm!: EggHatchViewModel;
  @Ref("form") form: any;

  getDMLG() {
    window.open(
      `https://pancakeswap.finance/swap?outputCurrency=${process.env.VUE_APP_TOKEN_ADDRESS}`,
      "_blank"
    );
  }

  async confirmSummon() {
    this.$emit("confirmSummon");
  }
}
