























































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Prop } from "vue-property-decorator";
import { NftModel } from "@/models/nft-model";
@Observer
@Component({
  components: {
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    CharacterAvatar: () => import("@/components/character/character-avatar.vue"),
    MonsterDetail: () => import("@/modules/egg-hatch-ticket/components/monster-detail.vue"),
  },
})
export default class MonsterCard extends Vue {
  @Prop() character!: NftModel;

  openCharacterDetail() {
    window.open(`${window.location.origin}/nft/${this.character.ID}`, "_blank");
  }
}
