import { localdata } from "@/helpers/local-data";
import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper } from "@/helpers/bignumber-helper";
import { EggHatchHandler } from "@/helpers/egg-hatch-handler";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from "mobx";
import { asyncAction, task } from "mobx-utils";
import moment from "moment";
import { Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { priceHelper } from "../../../helpers/pancakePriceHelper";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { appProvider } from "@/app-providers";
import { NftModel } from "@/models/nft-model";
import { shuffle } from "lodash";

export class EggHatchViewModel {
  _disposers: IReactionDisposer[] = [];
  private _unsubcrible = new Subject();

  @observable isFirstLoad = false;
  @observable tokenPrice = FixedNumber.from("0");

  @observable standardTicketNo = FixedNumber.from("0");
  @observable premiumTicketNo = FixedNumber.from("0");

  @observable approved = false;
  @observable approving = false;
  @observable lotteryApproved = false;
  @observable lotteryApproving = false;

  @observable stakedLP = FixedNumber.from("0");
  @observable userLPBalance = FixedNumber.from("0");
  @observable lastStakeTime: moment.Moment | null = null;
  @observable totalLockedAmount = FixedNumber.from("0");
  @observable totalValueLocked = FixedNumber.from("0");

  @observable usedTicket = FixedNumber.from("0");

  eggHatchHandler?: EggHatchHandler;

  // summon
  @observable summonDialog = false;
  @observable isSummonDialogLoading = false;
  @observable isPremiumSummon = false;
  @observable summonedNFTs: Array<NftModel> = [];
  @observable dataLoading = false;

  constructor() {
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.eggHatchHandler?.injectMetamask(walletStore.web3!);
        }
      ),
    ];
  }

  destroy() {
    this._unsubcrible.next();
    this._unsubcrible.complete();
    this._disposers.forEach((d) => d());
  }

  @asyncAction *loadData() {
    const eggHatchHandler = new EggHatchHandler();
    this.eggHatchHandler = eggHatchHandler;
    this.dataLoading = true;
    priceHelper.getTokenPrice().then((p) => {
      runInAction(() => (this.tokenPrice = priceHelper.tokenPriceBUSD));
    });
    try {
      yield eggHatchHandler.load();
    } catch (error) {
      console.error("loadData", error);
    }

    const standardTicketNo = yield this.eggHatchHandler.getStandardTicketNo();
    const premiumTicketNo = yield this.eggHatchHandler.getPremiumTicketNo();
    this.standardTicketNo = standardTicketNo;
    this.premiumTicketNo = premiumTicketNo;

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          //TODO: Change to mainnet
          if (walletStore.chainId == process.env.VUE_APP_CHAIN_ID) {
            eggHatchHandler.injectMetamask(walletStore.web3!);

            eggHatchHandler
              .approvedLottery(walletStore.account)
              .then((approved) => runInAction(() => (this.lotteryApproved = approved)));

            timer(0, 10000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo();
              });
          }
        }
      )
    );
    this.dataLoading = false;
  }

  @asyncAction *premiumSpin() {
    this.isSummonDialogLoading = true;
    try {
      yield this.eggHatchHandler!.premiumSpin(walletStore.account);

      // get info monster
      let nfts = yield appProvider.api.getNFTs({
        // eslint-disable-next-line
        ID: this.eggHatchHandler?.summonedIds,
      });
      nfts = shuffle(nfts);
      this.summonedNFTs = nfts;
      const newMonsters = localdata.getNewMonsters();
      localdata.setNewMonsters([...this.summonedNFTs, ...newMonsters]);
      return {
        result: true,
        message: "You have summoned 5 monsters successfully!",
      };
    } catch (error) {
      console.error(error);
      return {
        result: false,
        message: error.message.includes("Zero") ? "You do not have any premium ticket!" : error.message,
      };
    } finally {
      this.isSummonDialogLoading = false;
    }
  }
  @asyncAction *standardSpin() {
    this.isSummonDialogLoading = true;
    try {
      yield this.eggHatchHandler?.standardSpin(walletStore.account);

      // get info monster
      let nfts = yield appProvider.api.getNFTs({
        // eslint-disable-next-line
        ID: this.eggHatchHandler?.summonedIds,
      });
      nfts = shuffle(nfts);
      this.summonedNFTs = nfts;
      const newMonsters = localdata.getNewMonsters();
      localdata.setNewMonsters([...this.summonedNFTs, ...newMonsters]);
      return {
        result: true,
        message: "You have summoned 5 monsters successfully!",
      };
    } catch (error) {
      console.error(error);
      return {
        result: false,
        message: error.message.includes("Zero") ? "You do not have any standard ticket!" : error.message,
      };
    } finally {
      this.isSummonDialogLoading = false;
    }
  }

  @asyncAction *fetchPoolInfo() {
    if (!walletStore.account || !this.eggHatchHandler) {
      this.stakedLP = FixedNumber.from("0");
    } else {
      const [userLPBalance] = yield Promise.all([this.eggHatchHandler.getUserLPBalance(walletStore.account)]);
      this.userLPBalance = userLPBalance;
      this.standardTicketNo = yield this.eggHatchHandler.getStandardTicketNo();
      this.premiumTicketNo = yield this.eggHatchHandler.getPremiumTicketNo();

      if (!this.isFirstLoad) this.isFirstLoad = true;
    }
    this.dataLoading = false;
  }

  @asyncAction *approveLottery() {
    this.lotteryApproving = true;
    try {
      yield this.eggHatchHandler!.approveLottery(walletStore.account);
      this.lotteryApproved = true;
    } catch (error) {
      snackController.error(error.message);
    }
    this.lotteryApproving = false;
  }

  @action.bound standardSummon() {
    this.summonDialog = true;
    this.isPremiumSummon = false;
  }

  @action.bound premiumSummon() {
    this.summonDialog = true;
    this.isPremiumSummon = true;
  }

  @action.bound cancelSummonDialog() {
    this.summonDialog = false;
  }

  @computed get totalSummon() {
    return this.usedTicket.mulUnsafe(FixedNumber.from(5));
  }

  @computed get isConfirmSummon() {
    const isPremiumSummon = this.isPremiumSummon;
    if (isPremiumSummon) {
      return bigNumberHelper.gte(this.userLPBalance, FixedNumber.from("400"));
    } else {
      return bigNumberHelper.gte(this.userLPBalance, FixedNumber.from("200"));
    }
  }
}
